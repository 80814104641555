.auto-refresh {
	text-align: right;
	display: flex;
	flex-direction: column;

	.auto-refresh-button {
		cursor: pointer;
		float: right;
		color: #0c62ff;
		text-decoration: underline;
	}
}
