@import '../../../styles/globals';

.time-filter {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 8px;
	border: 1px solid $border-gray;
	background-color: $white;
	border-radius: 4px;
	height: 34px;
	margin-left: 8px;
	min-width: 390px;

	& .ant-picker-input > input {
		font-weight: normal;
		font-size: 13.5px;
		line-height: 130%;
		color: $blue5;
	}
}
