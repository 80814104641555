$bringg-orange: #ff4e26;
$bringg-background: #efefef;
$bringg-gray: #2d2d2d6e;
$white: #ffffff;
$black: #0c0c0c;
$transparent-black: #2f2f2f;
$absolute-black: #000000;
$light-gray: #d4d4d4;
$mid-gray: #a4a4a4;
$border-gray: #e1e1e1;
$text-light-gray: #8c8c8c;
$bringg-red: #ff5b5b;
$bringg-green: #3cba50;
$body-background-color: #f8f8f8;
$red: #ff4422;
$gray-text-color: #898989;
$error-color: red;
$warning-color: orange;
$blue5: #0c71ff;

$defaultFont: Rubik;
$xxs-font-size: 9px;
$xs-font-size: 11px;
$small-font-size: 12px;
$small-medium-font-size: 13px;
$medium-font-size: 16px;
$big-font-size: 20px;
$huge-font-size: 42px;
$default-font-weight: 400;
$big-font-weight: 500;
$default-font-size: 21px;
$mobile-font-size: 15px;
$bold: 900;
$mid-gray-2: #dadada;
$app-text-color: #000;

@mixin icon_style() {
	font-size: 18px;
	color: $bringg-orange;
	font-weight: 900;
}

@mixin default_font_styles() {
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.3px;
	font-family: $defaultFont;
}

@mixin widget_style() {
	border: 1px solid $border_gray;
	border-radius: 12px;
	background-color: $white;

	.title {
		font-size: $big-font-size;
		font-weight: $big-font-weight;
		color: $black;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important; // override chrome input styles
}
input::placeholder {
	color: $light-gray;
}

.input {
	border-radius: 0;
	border: none;
	border-bottom: 1px solid $light-gray;
	background: $white;
	background-color: $white;
	outline: none;
}

::placeholder {
	font-family: $defaultFont;
	font-style: italic;
}

.input:focus {
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	border-bottom: 1px solid $light-gray;
}

.input:active {
	outline: none;
	border-bottom: 1px solid $light-gray;
}

.input:hover {
	border-bottom: 1px solid $light-gray;
}
