.pie-donut {
	overflow: hidden;
	padding-top: 20px;
	padding-bottom: 20px;

	.apexcharts-tooltip {
		color: black;

		.apexcharts-tooltip-text-value {
			font-weight: 500;
		}
	}

	.apexcharts-tooltip-series-group {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.apexcharts-datalabels-group {
		& > text:nth-child(2) {
			transform: translateY(-15px);
		}
	}
}
