@import '../../styles/globals';

.grouping-select {
	border-top: 1px solid $border-gray;
	text-align: center;

	.grouping-option {
		color: $text-light-gray;
		font-size: $small-medium-font-size;
		cursor: pointer;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.selected-grouping-option {
		color: $red;
		border-top: 5px solid $red;
		font-weight: $big-font-weight;
		padding-top: 10px;
	}
}
