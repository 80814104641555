@import '../../styles/globals.scss';

.number-widget {
	color: $absolute-black;
	background-color: $white;

	.value,
	.positive-value,
	.negative-value {
		font-size: $huge-font-size;
		font-weight: $big-font-weight;
	}

	.positive-value {
		color: $bringg-green;
	}

	.negative-value {
		color: $bringg-red;
	}

	.target-value {
		font-weight: bolder;
		font-size: $medium-font-size;
		clear: both;
	}

	.target div {
		float: right;
	}

	.bottom-row {
		margin-top: 33px;
	}

	.metric-value {
		display: inline-block;
	}

	.target-title {
		font-weight: lighter;
		padding-top: 7px;
	}
}
