@import 'layout-header/layout-header';
@import 'layout-body/layout-body';

.app-layout {
	height: 100%;

	.layout-header {
		padding-bottom: 45px;
	}
	.layout-header,
	.layout-body {
		padding: 24px;
		padding-bottom: 16px;
	}
}
