@import '../../styles/globals.scss';

.widget-details {
	.error_text {
		color: $error-color;
	}

	.warning_text {
		color: $warning-color;
	}
}
