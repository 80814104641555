@import '../../styles/globals';

$dropdown-main-color: #000;

.bi-dropdown {
	color: $dropdown-main-color;

	.bi-dropdown-single-item-name {
		height: 24px;
		line-height: 24px;
		width: 200px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 6px;
	}

	.ant-select-sm .ant-select-selection--single {
		height: 30px;
	}

	.bi-dropdown-title {
		font-size: $small-font-size;
		margin-bottom: 8px;
		line-height: 1;
	}

	.ant-select-selection__rendered {
		margin-left: 0;
	}

	.ant-select {
		font-size: $small-medium-font-size;
		font-weight: $default-font-weight;

		.ant-select-selector {
			height: 33px;

			.ant-select-selection-item {
				display: flex;
				align-items: center;
			}
		}

		svg {
			color: $dropdown-main-color;
		}

		.ant-select-selection-selected-value {
			padding: 4px 0 2px 8px;
			color: $dropdown-main-color;
		}
	}
}
