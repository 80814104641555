@import '../../styles/globals';

.data-generated {
	color: $app-text-color;
	font-size: 11px;
	display: flex;
	flex-direction: column;
	margin-left: 20px;

	.data-generated-timestamp {
		font-weight: $big-font-weight;
	}

	.data-generated-title {
		text-align: left;
	}

	.data-generated-actions {
		font-size: 13px;
	}
}
