.layout-header {
	display: flex;
	align-items: flex-end;
	background-color: #fff;

	.header-dropdown {
		&:not(:first-child) {
			margin-left: 40px;
		}
	}

	.data-generated-col {
		display: flex;
		align-items: center;
		height: 34px;
	}

	.logout-col {
		margin-left: 30px;
	}
}
