@import '../../styles/globals.scss';

.target-percentage {
	color: $white;
	text-align: center;

	.neutral,
	.positive,
	.negative {
		min-width: 49px;
		border-radius: 4px;
		padding: 4px;
		float: right;
	}

	.neutral {
		background-color: $mid-gray;
	}

	.positive {
		background-color: $bringg-green;
	}

	.negative {
		background-color: $bringg-red;
	}

	.sign-icon {
		padding-left: 2px;
	}
}
