@import '../../styles/globals';

.widget-template {
	padding-bottom: 0;
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	height: 100%;

	.total-value {
		float: right;
	}

	.title {
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}

	.chart-item {
		overflow: hidden;
	}
}
