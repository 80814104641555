@import '../../../styles/globals';

.layout-body {
	height: 100%;
	background: $body-background-color;

	.timezone {
		float: right;
		margin-top: 30px;
	}
}
