@import '../../styles/globals';

.timezone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: auto;
	height: 34px;
	font-size: $small-medium-font-size;
	color: $gray-text-color;
	text-align: right;
	padding-left: 8px;

	.time-zone-title {
		font-size: $xs-font-size;
	}
}
