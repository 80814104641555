@import '../../styles/globals';

.no-data {
	margin-top: 48px;
	color: $app-text-color;
	font-weight: 500;

	.no-data-title {
		position: absolute;
		left: 15px;
		top: 15px;
		font-size: $big-font-size;
	}

	.no-data-text {
		font-size: $huge-font-size;
	}

	.children {
		font-weight: normal;
	}
}
