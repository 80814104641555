.bringg-bi {
	@import 'features/features.scss';
	@import 'components/components.scss';
	@import 'styles/globals';
	@include default_font_styles;

	height: auto;

	.main-spinner {
		text-align: center;
		margin-top: 300px;
		min-height: 110px;
	}
}

#bringg-bi-root {
	height: 100%;
}

.ant-picker-dropdown {
	z-index: 1071;
}
