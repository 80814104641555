@import '../../styles/globals';

h3.demo-label {
	color: red;
	text-align: center;
	margin-bottom: 18px;
}

.bi-widgets {
	.bi-spinner {
		text-align: center;
		min-height: 110px;
		margin-top: 50px;

		.ant-spin-dot-item {
			background-color: #000;
		}
	}
	.widget-card {
		@include widget_style;
		margin-bottom: 24px;

		.ant-card-body {
			word-wrap: break-word;
			padding: 16px;
		}
	}

	.apexcharts-xaxistooltip {
		background-color: $white;
		border: none;
	}

	.apexcharts-toolbar {
		display: none;
	}

	.apexcharts-legend-text {
		font-weight: 500;
		font-size: 8px;
		margin: 5px auto 0 auto;
	}

	.apexcharts-legend.position-bottom {
		.apexcharts-legend-series {
			display: grid;
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.apexcharts-xaxistooltip {
		display: none;
	}

	.apexcharts-gridline {
		display: none;
	}

	foreignObject {
		background-color: $white;
	}
	.apexcharts-tooltip {
		background-color: $transparent-black;
		border: 1px solid $white;
		border-radius: 5px;
		font-family: $defaultFont;
		font-size: $xxs-font-size;
		color: $white;
		line-height: 0.3;

		.apexcharts-tooltip-y-group {
			display: flex;
		}

		.apexcharts-tooltip-text {
			width: 100%;

			.apexcharts-tooltip-text-value {
				display: flex;
				width: 100%;
				justify-content: space-between;
			}
		}

		.apexcharts-tooltip-title {
			display: none;
		}
	}

	.apexcharts-legend.position-bottom.center {
		padding: 0;
	}

	line {
		stroke: $mid-gray;
	}
}
